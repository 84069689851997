import {Link} from 'react-router-dom'
import React from 'react'

const withMenu = (Component) => {
    return (props) => {
    return <>
        <Link to={'/'}>Home</Link><br />
        <Link to={'/videos'}>Videos</Link>
        <hr />
        <Component  {...props}/>
    </>
    }
}

export default withMenu