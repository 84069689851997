import { Routes, Route, BrowserRouter } from "react-router-dom"
import HomePage from './HomePage'
import VideosPage from "./VideosPage"
import React from 'react'


const Router = () => {
    return <BrowserRouter> 
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/videos" element={<VideosPage />} />
        </Routes>
    </BrowserRouter>
}

export default Router