import React from 'react'

const Video = ({id}) => {
    return <iframe 
    width="560" 
    height="315" 
    src={`https://www.youtube.com/embed/${id}`}
    title="YouTube video player"></iframe>
}

export default Video