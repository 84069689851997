import styles from './VideosPage.module.css'
import withMenu from '../../HOCs/withMenu'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import Video from '../../components/Video'
import React from 'react'

const VideosPage = () => {

    const [videos, setVideos] = useState(null)

    const ax = useMemo(() => {
        return axios.create({
            baseURL: 'https://share.srv1.tech',
            withCredentials: false
        })
    })

    useEffect(() => {
        ax.get('/videos.json').then(e => setVideos(e.data.videos))
    }, [])

    return videos !== null ? <div>
        {videos.map((item, index) => {
            return <Video key={index} id={item.id} />
        })}
    </div> : null
}

export default withMenu(VideosPage)